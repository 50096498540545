<script setup lang="ts">
import { NotificationAlertProps } from "../NotificationAlert.props"
import { DiscountCategoryProps } from "./DiscountCategory.props"
import { getFormatDate } from "~/utils/date"

const props = defineProps<DiscountCategoryProps>()

const { t } = useI18n()
const { applyCoupon } = useCart()
const { addNotification } = useNotification()

const isPromoCodeCopied = ref(false)

const convertedDate = getFormatDate(props.expirationDate)
const currentDate = Date.now()
const isPromoValid = props.expirationDate - currentDate > 0

const expirationMessage = `${
  isPromoValid
    ? `${t(`discounts.until`)} **${convertedDate}** - ${t(
        `discounts.nonCumulative`
      )}`
    : t(`discounts.expired`)
}`

const applyToCart = () => {
  const notificationWarn: NotificationAlertProps = {
    id: Math.random(),
    type: "warning",
    notificationAlertText: t("discounts.codeAppliedError"),
    isWithCloseIcon: true
  }
  applyCoupon(props.promoCode)
    .then(() => {
      isPromoCodeCopied.value = true
    })
    .catch(() => addNotification(notificationWarn))
}
</script>

<template>
  <div
    class="
      relative
      w-[288px]
      rounded-lg
      bg-white
      p-4
      shadow-01
      transition
      hover:shadow-02
      md:flex
      md:w-auto
    "
  >
    <div class="hidden md:block md:max-w-[204px]">
      <UtilsWrapperImage
        v-if="category.image"
        v-bind="category.image"
        :key="category.image.image.url"
        class="
          wrapper-image-full-container-centered wrapper-image-1
          w-full
          overflow-hidden
          rounded-lg
        "
      />
    </div>
    <div class="flex flex-col gap-4 md:w-full md:py-2 md:pl-6">
      <div class="flex items-center gap-2">
        <div class="h-10 w-10 shrink-0 overflow-hidden rounded-full">
          <UtilsWrapperImage
            v-if="category.image"
            v-bind="category.image"
            class="wrapper-image-full-container-centered"
          />
        </div>
        <span v-if="category.name" class="mouse-bold md:beaver-bold uppercase">
          {{ category.name }}
        </span>
      </div>

      <div class="flex items-center gap-4">
        <UtilsWrapperImage
          v-if="category.image"
          v-bind="category.image"
          :key="category.image.image.url"
          class="
            wrapper-image-full-container-centered
            wrapper-image-fixed-96
            wrapper-image-1
            overflow-hidden
            rounded-lg
            md:hidden
          "
        />
        <span v-if="title" class="pig-bold md:elephant-bold text-green-main">
          {{ title }}
        </span>
      </div>
      <div class="mouse flex items-start gap-2">
        <span class="font-bold">{{ $t("discounts.validity") }}: </span>
        <UtilsMarkdown :content="expirationMessage" />
      </div>

      <div class="flex flex-col gap-4 md:flex-row md:flex-wrap">
        <button
          class="
            pig-medium
            cursor-default
            rounded-md
            border border-tiffany-60
            py-2
            text-center
            uppercase
            md:flex-1
          "
        >
          {{ promoCode }}
        </button>

        <UtilsButton
          v-if="isPromoValid"
          class="md:flex-1"
          :class="{
            '[&>span]:!bg-positive-main [&>span]:!text-white': isPromoCodeCopied
          }"
          :text="
            isPromoCodeCopied
              ? $t(`discounts.applied.ctaText`)
              : $t(`discounts.ready.ctaText`)
          "
          order="last"
          :is-disabled="isPromoCodeCopied"
          @click="applyToCart"
        >
          <template #icon>
            <IconsCheckmark v-if="isPromoCodeCopied" class="h-5 w-5" />
          </template>
        </UtilsButton>
      </div>
    </div>
  </div>
</template>
