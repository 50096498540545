<script setup lang="ts">
import { CategoryDiscountProps } from "./CategoryDiscount.props"
import { smOptions, mdOptions } from "./CategoryDiscount.carouselOptions"
import { sm } from "~/utils/breakpoints"
import { WrapperImageProps } from "~/components/Utils/WrapperImage.props"

withDefaults(defineProps<CategoryDiscountProps>(), {
  bgImageActive: false
})

const carouselOptions = computed(() => (sm.value ? smOptions : mdOptions))

const bgImage: WrapperImageProps = getImageData({
  altText: "background",
  url: "/i5oh5hzamkwo/36wBMRS3BuqrAYUdpeYyvj/c4862ad2b73c5497f21e8217b964b482/cover-orange-gradient.svg",
  width: 1024,
  height: 220
})
</script>

<template>
  <div
    class="
      padded
      grid-standard
      carousel-container-overflowed
      relative
      gap-6
      py-6
      md:items-center
    "
  >
    <UtilsWrapperImage
      v-if="bgImageActive"
      v-bind="bgImage"
      :key="bgImage.image.url"
      class="
        wrapper-image-full-container-centered
        absolute
        inset-0
        -z-10
        overflow-hidden
      "
    />
    <div class="col-span-full -ml-4 pl-4 md:col-span-4">
      <p class="pig-bold md:whale-bold mb-2 md:mb-6">
        {{ title }}
      </p>

      <p class="mouse md:pig">
        {{ description }}
      </p>
    </div>

    <div
      class="
        carousel-container-overflowed
        col-span-full
        w-full
        md:col-span-7 md:col-start-7
        md:-ml-10 md:-mr-[71px]
        md:w-auto
        md:overflow-x-hidden
        md:py-8
        md:pl-10
        md:pr-[71px]
        lg:-mr-[228px]
        lg:pr-[228px]
      "
    >
      <AppCarousel
        v-if="cards?.length"
        class="carousel-overflowed select-none"
        :items="cards"
        :swiper-props="carouselOptions"
        :theme="carouselOptions?.theme"
        :key="sm ? 1 : 0"
      >
        <template #default="{ item }">
          <CardDiscountCategory v-bind="item" />
        </template>
      </AppCarousel>
    </div>
  </div>
</template>
