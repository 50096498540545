<script setup lang="ts">
import type { ComponentDiscountsRailProps } from "./ComponentDiscountsRail.props"
const props = defineProps<ComponentDiscountsRailProps>()

const dataDiscountRail = computed(() => {
  return {
    title: props.title,
    description: props.subtitle,
    cards:
      props?.cards.length > 0
        ? props?.cards.map((card) => {
            if (props.type === "ProductCategory") {
              return {
                category: {
                  name: card.category,
                  image: card.categoryImage
                },
                image: card.image,
                title: card.title,
                promoCode: card.discountCode,
                expirationDate: card.expirationDate
              }
            } else {
              return {
                brand: {
                  name: card.category,
                  image: card.categoryImage
                },
                title: card.title,
                description: card.description,
                promoCode: card.discountCode,
                expirationDate: card.expirationDate
              }
            }
          })
        : []
  }
})
</script>

<template>
  <div class="cmp-discount-rails" v-if="dataDiscountRail">
    <RailsDiscount
      v-if="type === 'Brand'"
      v-bind="dataDiscountRail"
      id="codici-sconto-marche"
    />
    <RailsCategoryDiscount
      v-else
      v-bind="dataDiscountRail"
      bg-image-active
      id="codici-sconto-categorie"
    />
  </div>
</template>
