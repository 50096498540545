import { CarouselProps } from "~/utils/carouselProps"
import { Navigation } from "swiper/modules"

export const smOptions: CarouselProps = {
  slidesPerView: "auto",
  grabCursor: true
}

export const mdOptions: CarouselProps = {
  slidesPerView: "auto",
  grabCursor: true,
  navigation: true,
  modules: [Navigation],
  theme: "navigation-dark-middle"
}
