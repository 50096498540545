<script setup lang="ts">
import { DiscountProps } from "./Discount.props"
import { WrapperImageProps } from "~/components/Utils/WrapperImage.props"
import { getImageData } from "~/utils/wrapperImage"
import { smOptions, mdOptions } from "./Discount.carouselOptions"
import { sm } from "~/utils/breakpoints"

defineProps<DiscountProps>()

const carouselOptions = computed(() => (sm.value ? smOptions : mdOptions))

const bgImage: WrapperImageProps = getImageData({
  altText: "background",
  url: "/i5oh5hzamkwo/4ttzu0hNoMrcJISy1SK9Bp/ce7326be9b2cefe3a234795f7e527550/cover-green-gradient.svg",
  width: 1024,
  height: 548
})
</script>

<template>
  <div
    class="
      carousel-container-overflowed
      discount-rail-wrapper
      padded
      relative
      py-4
      md:py-9
    "
  >
    <UtilsWrapperImage
      v-bind="bgImage"
      :key="bgImage.image.url"
      class="
        wrapper-image-full-container-centered
        absolute
        inset-0
        -z-10
        overflow-hidden
      "
    />

    <p class="elephant-bold md:mammoth-bold mb-2 text-white">
      {{ title }}
    </p>

    <div class="mb-6 flex items-center gap-2 text-white md:mb-10">
      <UtilsIcon name="Info.svg" color="white" class="h-6 w-6 flex-shrink-0" />
      <p class="pig-medium">
        {{ description }}
      </p>
    </div>
    <AppCarousel
      v-if="cards"
      :swiper-props="carouselOptions"
      :theme="carouselOptions?.theme"
      :items="cards"
      class="carousel-overflowed relative"
      :key="sm ? 1 : 0"
    >
      <template #default="{ item }">
        <CardDiscountBrand class="swiper-tile" v-bind="item" />
      </template>
    </AppCarousel>
  </div>
</template>

<style lang="scss" scoped>

:deep(.swiper-slide:first-child > div) {
  margin-left: 0;
}

:deep(.swiper-slide:last-child > div) {
  margin-right: 0;
}
.swiper-tile {
  margin: 0 8px;
}

@screen md {
  .swiper-tile {
    margin: 0 12px;
    width: calc(((100vw - 142px) / 4) - 24px);
  }
}

@screen lg {
  .swiper-tile {
    width: calc(((100vw - 456px) / 4) - 24px);
  }
}

</style>
