<script setup lang="ts">
import { DiscountBrandProps } from "./DiscountBrand.props"
import { getFormatDate } from "~/utils/date"
import { NotificationAlertProps } from "../NotificationAlert.props"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"

const props = defineProps<DiscountBrandProps>()

const { t } = useI18n()

const { applyCoupon } = useCart()
const { addNotification } = useNotification()
const isLogged = useIsLogged()
const { copy } = useClipboard()
const { cart } = useCart()

const isPromoCodeCopied = ref(false)

const convertedDate = getFormatDate(props.expirationDate)
const currentDate = Date.now()
const isPromoValid = props.expirationDate - currentDate > 0

const expirationMessage = `${t(`discounts.validity`)}: ${
  isPromoValid
    ? `${t(`discounts.until`)} **${convertedDate}**`
    : t(`discounts.expired`)
}`

const applyToCart = () => {
  const notificationWarn: NotificationAlertProps = {
    id: Math.random(),
    type: "warning",
    notificationAlertText: t("discounts.codeAppliedError"),
    isWithCloseIcon: true
  }
  applyCoupon(props.promoCode)
    .then(() => {
      isPromoCodeCopied.value = true
    })
    .catch(() => addNotification(notificationWarn))
}
// So we dont expose the code
const visibleDiscountCode = computed(() =>
  !isLogged.value && props.promoCode
    ? `${props.promoCode.slice(-2)}`
    : `${props.promoCode}`
)

const handleCopyToClipboard = () => {
  copy(props.promoCode)
  const notification: NotificationAlertProps = {
    id: Math.random(),
    type: "success",
    notificationAlertText: t("discounts.copyCodeToClipboard"),
    isWithCloseIcon: true
  }

  addNotification(notification)
}

//Watch for a change in the coupon code and re enable the button when the code
//is different from the one applied previously
watch(
  () => cart.value?.coupon_code,
  (newCoupon) => {
    if (newCoupon !== props.promoCode) {
      isPromoCodeCopied.value = false
    }
  }
)
</script>

<template>
  <div
    class="
      flex
      w-[242px]
      flex-col
      items-center
      gap-3
      rounded-lg
      bg-white
      px-2
      py-4
      shadow-01
      md:w-auto
    "
  >
    <UtilsWrapperImage
      class="wrapper-image-full-container-centered !h-[83px] md:!h-[100px]"
      v-if="brand.image"
      :="brand.image"
    />
    <p class="elephant-bold">{{ brand.name }}</p>
    <div class="flex flex-col items-center gap-2 text-center text-green-main">
      <p v-if="title" class="dolphin-bold">{{ title }}</p>
      <p v-if="description" class="beaver-medium">{{ description }}</p>
      <UtilsMarkdown
        :content="expirationMessage"
        class="mouse text-black-main"
      />
    </div>
    <div class="relative w-full">
      <div class="relative overflow-hidden rounded-lg">
        <UtilsButton
          :text="`${visibleDiscountCode}`"
          fluid
          theme="outlined-green"
          :class="[
            'custom-btn-discount-code-brand',
            isLogged ? 'unlocked' : 'locked'
          ]"
          @click="handleCopyToClipboard"
        />
        <div
          v-if="!isLogged"
          class="
            pig-medium
            absolute
            inset-0
            right-10
            flex
            items-center
            justify-center
            bg-tiffany-400
            text-white
          "
        >
          {{ $t("discounts.discountCode") }}
        </div>
      </div>
      <IconsFoldedLip
        v-if="!isLogged"
        class="absolute right-[25px] top-0 text-green-70"
      />
    </div>
    <UtilsButton
      v-if="isPromoValid && isLogged"
      :class="{
        '[&>span]:!bg-positive-main [&>span]:!text-white': isPromoCodeCopied
      }"
      :text="
        isPromoCodeCopied
          ? $t(`discounts.applied.ctaText`)
          : $t(`discounts.ready.ctaText`)
      "
      fluid
      order="last"
      :is-disabled="isPromoCodeCopied"
      @click="applyToCart"
    >
      <template #icon>
        <IconsCheckmark v-if="isPromoCodeCopied" class="h-5 w-5" />
      </template>
    </UtilsButton>
    <UtilsButton
      v-else-if="isPromoValid && !isLogged"
      :text="$t(`discounts.logInOrSignUp`)"
      fluid
      @click="navigateToLoginSignupPage"
    >
    </UtilsButton>
  </div>
</template>
